import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Icon } from 'app/components/Common/Icon'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo, useState } from 'react'

import { Props as RoomItemProps, RoomItem } from './item'

export interface Props {
  rooms?: RoomItemProps[]
  languageCode: string
}

export const RoomsList = memo(function RoomsList({
  rooms,
  languageCode,
}: Props) {
  const [activeFilter, setActiveFilter] = useState('')
  return (
    <Container>
      <FadeInUp>
        <Filters>
          <FiltersTitle>
            {useVocabularyData('rooms-filters-title', languageCode)}
          </FiltersTitle>
          <Filter
            onClick={() => setActiveFilter('')}
            className={!activeFilter ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-offers-all.svg" alt="filter all" />
            <FilterLabel>{useVocabularyData('all', languageCode)}</FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveFilter('guests_1_2')}
            className={activeFilter == 'guests_1_2' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-rooms-pax.svg" alt="filter pax" />
            <FilterLabel>
              {useVocabularyData('rooms-filters-guests_1_2', languageCode)}
            </FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveFilter('guests_3_4')}
            className={activeFilter == 'guests_3_4' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-rooms-pax.svg" alt=" filter pax" />
            <FilterLabel>
              {useVocabularyData('rooms-filters-guests_3_4', languageCode)}
            </FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveFilter('pillow_menu')}
            className={activeFilter == 'pillow_menu' ? 'active' : ''}
          >
            <FilterIco
              src="/icon_filter-rooms-pillow.svg"
              alt="filter pillow"
            />
            <FilterLabel>
              {useVocabularyData('rooms-filters-pillow', languageCode)}
            </FilterLabel>
          </Filter>

          <Filter
            onClick={() => setActiveFilter('double_bathroom')}
            className={activeFilter == 'double_bathroom' ? 'active' : ''}
          >
            <FilterIco src="/icon_filter-rooms-bath.svg" alt="filter bath" />
            <FilterLabel>
              {useVocabularyData('rooms-filters-bath', languageCode)}
            </FilterLabel>
          </Filter>
        </Filters>
      </FadeInUp>

      {rooms ? (
        <List>
          {rooms.map((item, index) => (
            <StyledRoomItem
              idx={index}
              key={index}
              {...item}
              className={
                activeFilter && !item.filtersGroup?.includes(activeFilter)
                  ? 'hidden'
                  : ''
              }
            />
          ))}
        </List>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  padding: 6.66vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight5};

  @media (max-width: 991px) {
    padding: 30px;
  }
`

const Filters = styled.div`
  text-align: center;
  margin-bottom: 3vw;

  @media (max-width: 991px) {
    display: none;
  }
`
const FiltersTitle = styled.p`
  margin-bottom: 1vw;
`
const FilterIco = styled(Icon)`
  transition: all 200ms linear;
`
const FilterLabel = styled.div`
  font-size: 1vw;
  font-weight: 300;
  opacity: 0.5;
`
const Filter = styled.div`
  display: inline-block;
  text-align: center;
  width: 6vw;
  margin: 0 10px;
  cursor: pointer;

  &.active {
    ${FilterIco} {
      background: ${({ theme }) => theme.colors.variants.neutralDark4};
      img {
        filter: brightness(1000%);
      }
    }
    ${FilterLabel} {
      opacity: 1;
    }
  }

  &:hover ${FilterIco} {
    opacity: 0.8;
  }
`

const List = styled.div`
  display: flex;
  margin-top: 5vw;
  margin-left: -3vw;
  margin-right: -3vw;
  flex-wrap: wrap;
  padding-top: 0;

  @media (max-width: 991px) {
    display: block;
    margin: 0;
  }
`
const StyledRoomItem = styled(RoomItem)`
  &.hidden {
    display: none;
  }
`
